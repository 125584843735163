<template>
  <List
    v-model="loading"
    v-bind="$attrs"
    v-on="$listeners"
    :finished-text="finishedText"
    :error-text="errorText"
  >
    <CheckboxGroup
      ref="checkboxGroup"
      v-model="selectionList"
      @change="onSelectionChange"
    >
      <ul class="list">
        <li v-for="(item, index) in dataList" :key="index" class="list-item">
          <div v-if="showSelection" class="checkbox-wrap">
            <Checkbox :name="item.id" /> 
          </div>
          <div class="card-wrap">
            <slot :item="item" />
          </div>
        </li>
      </ul>
    </CheckboxGroup>
  </List>
</template>
<script>
import { Checkbox, CheckboxGroup, List } from 'vant'
export default {
  name: 'v-list',
  components: {
    CheckboxGroup,
    Checkbox,
    List
  },
  props: {
    value: { // 是否处于加载状态，加载过程中不触发load事件
      type: Boolean,
      default: false
    },
    dataList: { // 数据列表
      type: Array,
      default: () => []
    },
    showSelection: { // 是否可选择
      type: Boolean,
      default: false
    },
    selection: { // 当前已选择的数据
      type: Array,
      default: () => []
    },
    finishedText: {
      type: String,
      default: 'No more items.'
    },
    errorText: {
      type: String,
      default: '请求失败，点击重新加载'
    }
  },
  data() {
    return {
      loading: false,
      selectionList: []
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.loading = value
      }
    },
    selection: {
      immediate: true,
      handler(value) {
        console.log(value)
        this.selectionList = value
      }
    },
    loading(value) {
      this.$emit('input', value)
    }
  },
  methods: {
    onSelectionChange(value) {
      console.log(value)
      this.$emit('on-selection-change', value)
    },
    /**
     * @param checked
     *  true      : 全部选中
     *  false     : 全部取消
     *  undefined : 全部反选
     */
    toggleAll(checked) {
      this.$refs.checkboxGroup.toggleAll(checked)
    }
  }
}
</script>
<style lang="less" scoped>
.list {
  // padding-top: 16px;

  .list-item {
    display: flex;
    align-items: center;
    // margin-bottom: -4px;

    .checkbox-wrap {
      padding: 0 24px;
    }

    .card-wrap {
      flex: 1;
    }
  }
}
</style>